import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Images from "../../commonComponents/Images/Images";
import intlTelInput from "intl-tel-input";
import axios from "axios";

const BookADemoModal = ({ onHide, show }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [valid, setValid] = useState(false);
  // const [countryData, setCountryData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_no: "",
    organization: "",
    prefered_date: "",
    prefered_time: "",
    to: "info@digidoc.co.in",
    //  #info@digidoc.co.in
    form_type: "demo",
  });

  const [currentStep, setCurrentStep] = useState(1);

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // const handlePhoneChange = (isValid, value, countryData, number, id) => {
  //     setPhoneNumber(value);
  //     setValid(isValid);
  //     setCountryData(countryData);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile_no") {
      if (/^\d{0,10}$/.test(value)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    clearError(name);
  };

  const clearError = (name) => {
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
  };

  const validateStep1 = () => {
    let formIsValid = true;
    const newErrors = {};

    if (!formData.prefered_date) {
      newErrors.prefered_date = "Preferred Date is required.";
      formIsValid = false;
    }

    if (!formData.prefered_time) {
      newErrors.prefered_time = "Preferred Time is required.";
      formIsValid = false;
    }

    // Check email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const validateStep2 = () => {
    let formIsValid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required.";
      formIsValid = false;
    }

    if (!formData.organization || formData.organization === "") {
      newErrors.organization = "Organization is required.";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  // api integration of book a demo modal

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const bookDemoModalEndpoint = "/demo";
  const bookDemoModalUrl = `${apiUrl}${bookDemoModalEndpoint}`;

  const handleSubmitStep1 = (e) => {
    e.preventDefault();
    if (validateStep1()) {
      setCurrentStep(2);
    } else {
      console.log("failed");
    }
  };

  const handleSubmitStep2 = (e) => {
    e.preventDefault();
    if (validateStep2()) {
      setSubmitted(true);

      const requestData = {
        name: formData.name,
        email: formData.email,
        mobile_no: formData.mobile_no,
        organization: formData.organization,
        prefered_date: formData.prefered_date,
        prefered_time: formData.prefered_time,
        // tomail: formData.tomail,
        to: formData.tomail,
        form_type: formData.form_type,
      };

      axios
        .post(bookDemoModalUrl, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === 1 && res.data.code === 200) {
            setFormData({
              name: "",
              email: "",
              mobile_no: "",
              organization: "",
              prefered_date: "",
              prefered_time: "",
              tomail: "",
              form_type: "",
            });
            setSubmitted(true);
          } else {
            console.error("Error:", res.data.message);
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setSubmitted(false);
        });
    }
  };

  const handleClose = () => {
    setSubmitted(false);
    setCurrentStep(1);
    onHide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep2()) {
      setSubmitted(true);
      try {
        const response = await fetch("https://digidoc.co.in/api/mail.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.status === "success") {
          setFormData({
            name: "",
            email: "",
            mobile_no: "",
            organization: "",
            prefered_date: "",
            prefered_time: "",
            tomail: "",
            form_type: "",
          });
          setSubmitted(true);
        } else {
          setSubmitted(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setSubmitted(false);
      }
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* {submitted && */}
      {/* <Modal.Header>
                    <img src={Images.closeBtn} alt="close-btn" className="img-fluid" onClick={handleClose} />
                </Modal.Header> */}
      {/* } */}
      <Modal.Body>
        <img
          src={Images.closeBtn}
          alt="close-btn"
          className="img-fluid close-modal"
          onClick={handleClose}
        />

        <div className="row mxy-20 align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="img-div">
              <img
                src={Images.demoModalImg}
                alt="Contact Us"
                className="img-fluid"
              />
            </div>
          </div>
          {!submitted ? (
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h4 className="text-left mb-2">Book a Demo</h4>
              {currentStep === 1 ? (
                <form
                  className="book-demoModal-form"
                  onSubmit={handleSubmitStep1}
                >
                  <div>
                    <div className="mb-2">
                      <label for="email" className="form-label">
                        Email<span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email address here"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <p className="error-message">{errors.email}</p>
                      )}
                    </div>
                    <div className="mb-2">
                      <label for="date" className="form-label">
                        Preferred Date<span>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control preferred-time"
                        name="prefered_date"
                        value={formData.prefered_date}
                        onChange={handleChange}
                        min={getTodayDate()}
                      />
                      {errors.prefered_date && (
                        <p className="error-message">{errors.prefered_date}</p>
                      )}
                    </div>
                    <div className="mb-2">
                      <label for="time" className="form-label">
                        Preferred Time<span>*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control preferred-time"
                        name="prefered_time"
                        value={formData.prefered_time}
                        onChange={handleChange}
                      />
                      {errors.prefered_time && (
                        <p className="error-message">{errors.prefered_time}</p>
                      )}
                    </div>
                    <div className="btn-div mt-3">
                      <button className="btn btn-sm btn-yellow" type="submit">
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <form className="book-demoModal-form" onSubmit={handleSubmit}>
                  <div>
                    <div className="mb-2">
                      <label for="name" className="form-label">
                        Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Full Name here"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <p className="error-message">{errors.name}</p>
                      )}
                    </div>
                    <div className="mb-2">
                      <label for="phone" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        name="mobile_no"
                        value={formData.mobile_no}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-2">
                      <label for="text" className="form-label">
                        Organization<span>*</span>
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        id="formOrganization"
                        name="organization"
                        value={formData.organization}
                        onChange={handleChange}
                      >
                        <option value="" selected disabled>
                          Select your Organization
                        </option>
                        <option value="hospital">Hospital</option>
                        <option value="clinic">Clinic</option>
                      </select>
                      {errors.organization && (
                        <p className="error-message">{errors.organization}</p>
                      )}
                    </div>
                    <div className="btn-div mt-3">
                      <button
                        className="btn btn-sm btn-yellow"
                        type="submit"
                        disabled={submitted}
                      >
                        {submitted ? "Booking..." : "Book a Demo"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          ) : (
            <div className="col-lg-6 col-md-12 col-sm-12 thankYou-div">
              <div className="img-div my-20">
                <img src={Images.successImg} alt="Contact Us" width={"120px"} />
              </div>
              <h5>Your request has been received</h5>
              <p className="text-center">
                One of our experts will contact you shortly to schedule your
                demo
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BookADemoModal;

// logo
import digidoc_logo from "../../assets/images/svg/digidoc_logo.svg";
// banner section
import ABDM from "../../assets/images/svg/ABDM.svg";
import AWS from "../../assets/images/svg/AWS.svg";
import rightImg from "../../assets/images/png/HomeBannerImages/right-img.png";
import leftImg from "../../assets/images/png/HomeBannerImages/left-img.png";
import centerImg from "../../assets/images/png/HomeBannerImages/center-img.png";
// slider
import slider1 from "../../assets/images/png/SliderImages/slider1.png";
import slider2 from "../../assets/images/png/SliderImages/slider2.png";
import slider3 from "../../assets/images/png/SliderImages/slider3.png";
import slider4 from "../../assets/images/png/SliderImages/slider4.png";
import slider5 from "../../assets/images/png/SliderImages/slider5.png";
import smallSlider1 from "../../assets/images/png/SliderImages/smallSlider1.svg";
import smallSlider2 from "../../assets/images/png/SliderImages/smallSlider2.svg";
import smallSlider3 from "../../assets/images/png/SliderImages/smallSlider3.svg";
import smallSlider4 from "../../assets/images/png/SliderImages/smallSlider4.svg";
import smallSlider5 from "../../assets/images/png/SliderImages/smallSlider5.svg";
// aboutus
import aboutUsImg from "../../assets/images/png/aboutUsImg.png";
import ellipse_background_img from "../../assets/images/png/ellipse_background_img.png";
// this is critical for section
import thisIsCriticalFor1 from "../../assets/images/svg/ThisIsCriticalFor/svg/thisIsCriticalFor1.svg";
import thisIsCriticalFor2 from "../../assets/images/svg/ThisIsCriticalFor/svg/thisIsCriticalFor2.svg";
import thisIsCriticalFor3 from "../../assets/images/svg/ThisIsCriticalFor/svg/thisIsCriticalFor3.svg";
import thisIsCriticalFor4 from "../../assets/images/svg/ThisIsCriticalFor/svg/thisIsCriticalFor4.svg";
import thisIsCriticalFor5 from "../../assets/images/svg/ThisIsCriticalFor/svg/thisIsCriticalFor5.svg";

import thisIsCriticalFor1Gif from "../../assets/images/svg/ThisIsCriticalFor/Gifs/thisIsCriticalFor1.gif";
import thisIsCriticalFor2Gif from "../../assets/images/svg/ThisIsCriticalFor/Gifs/thisIsCriticalFor2.gif";
import thisIsCriticalFor3Gif from "../../assets/images/svg/ThisIsCriticalFor/Gifs/thisIsCriticalFor3.gif";
import thisIsCriticalFor4Gif from "../../assets/images/svg/ThisIsCriticalFor/Gifs/thisIsCriticalFor4.gif";
import thisIsCriticalFor5Gif from "../../assets/images/svg/ThisIsCriticalFor/Gifs/thisIsCriticalFor5.gif";
// digidoc features
import prescriptionManagement1 from "../../assets/images/png/DigidocFeatures/prescriptionManagement1.png";
import prescriptionManagement2 from "../../assets/images/png/DigidocFeatures/Prescription Management2.png";
import medicationReminders1 from "../../assets/images/png/DigidocFeatures/medicationReminders1.png";
import medicationReminders2 from "../../assets/images/png/DigidocFeatures/medicationReminders2.png";
import adherenceTracking from "../../assets/images/png/DigidocFeatures/adherenceTracking.png";
import doctorConnectivity from "../../assets/images/png/DigidocFeatures/doctorConnectivity.png";
import UI1 from "../../assets/images/png/DigidocFeatures/UI1.png";
import UI2 from "../../assets/images/png/DigidocFeatures/UI2.png";
import UI3 from "../../assets/images/png/DigidocFeatures/UI3.png";
import localLanguageSupport from "../../assets/images/png/DigidocFeatures/localLanguageSupport.png";
import chornicCare from "../../assets/images/png/DigidocFeatures/chornicCare.png";
import dataHandling from "../../assets/images/png/DigidocFeatures/dataHandling.png";
import leftArrow from "../../assets/images/svg/leftArrow.svg";
import rightArrow from "../../assets/images/svg/rightArrow.svg";
import ThreeMobi from "../../assets/images/png/DigidocFeatures/Three-Mobile.png";

// why do we need digidoc
import whyDoWeNeedDigidoc1 from "../../assets/images/svg/WhyDoWeNeedDigidoc/whyDoWeNeedDigidoc1.svg";
import whyDoWeNeedDigidoc2 from "../../assets/images/svg/WhyDoWeNeedDigidoc/whyDoWeNeedDigidoc2.svg";
import forDoctors1 from "../../assets/images/svg/forDoctors/forDoctors1.svg";
import forDoctors2 from "../../assets/images/svg/forDoctors/forDoctors2.svg";
import forDoctors3 from "../../assets/images/svg/forDoctors/forDoctors3.svg";
import forDoctors4 from "../../assets/images/svg/forDoctors/forDoctors4.svg";
import forDoctors5 from "../../assets/images/svg/forDoctors/forDoctors5.svg";
import forPatients1 from "../../assets/images/svg/forPatients/forPatients1.svg";
import forPatients2 from "../../assets/images/svg/forPatients/forPatients2.svg";
import forPatients3 from "../../assets/images/svg/forPatients/forPatients3.svg";
import forPatients4 from "../../assets/images/svg/forPatients/forPatients4.svg";
import forPatients5 from "../../assets/images/svg/forPatients/forPatients5.svg";
// digidoc unique
import digidocUnique1 from "../../assets/images/svg/DigidocUnique/digidocUnique1.png";
import digidocUnique2 from "../../assets/images/svg/DigidocUnique/digidocUnique2.svg";
import digidocUnique3 from "../../assets/images/svg/DigidocUnique/digidocUnique3.svg";
import digidocUnique4 from "../../assets/images/svg/DigidocUnique/digidocUnique4.svg";
import digidocUnique5 from "../../assets/images/svg/DigidocUnique/digidocUnique5.svg";
import digidocUnique6 from "../../assets/images/svg/DigidocUnique/digidocUnique6.svg";
// register now section
import registerNowImg from "../../assets/images/svg/registerNowImg.svg";
// contact us form modal
import contactUsFormImg from "../../assets/images/svg/contactUsFormImg.svg";
import successImg from "../../assets/images/svg/successImg.svg";
import closeBtn from "../../assets/images/svg/closeBtn.svg";
// book a demo modal
import demoModalImg from "../../assets/images/svg/demoModalImg.svg";

const Images = {
  // logo
  digidoc_logo: digidoc_logo,
  // banner section
  ABDM: ABDM,
  AWS: AWS,
  rightImg: rightImg,
  leftImg: leftImg,
  centerImg: centerImg,
  // slider
  slider1: slider1,
  slider2: slider2,
  slider3: slider3,
  slider4: slider4,
  slider5: slider5,
  smallSlider1: smallSlider1,
  smallSlider2: smallSlider2,
  smallSlider3: smallSlider3,
  smallSlider4: smallSlider4,
  smallSlider5: smallSlider5,
  // aboutus
  aboutUsImg: aboutUsImg,
  ellipse_background_img: ellipse_background_img,
  // this is critical for section
  thisIsCriticalFor1: thisIsCriticalFor1,
  thisIsCriticalFor2: thisIsCriticalFor2,
  thisIsCriticalFor3: thisIsCriticalFor3,
  thisIsCriticalFor4: thisIsCriticalFor4,
  thisIsCriticalFor5: thisIsCriticalFor5,

  thisIsCriticalFor1Gif: thisIsCriticalFor1Gif,
  thisIsCriticalFor2Gif: thisIsCriticalFor2Gif,
  thisIsCriticalFor3Gif: thisIsCriticalFor3Gif,
  thisIsCriticalFor4Gif: thisIsCriticalFor4Gif,
  thisIsCriticalFor5Gif: thisIsCriticalFor5Gif,
  // digidoc features
  prescriptionManagement1: prescriptionManagement1,
  prescriptionManagement2: prescriptionManagement2,
  medicationReminders1: medicationReminders1,
  medicationReminders2: medicationReminders2,
  adherenceTracking: adherenceTracking,
  doctorConnectivity: doctorConnectivity,
  UI1: UI1,
  UI2: UI2,
  UI3: UI3,
  localLanguageSupport: localLanguageSupport,
  chornicCare: chornicCare,
  dataHandling: dataHandling,
  leftArrow: leftArrow,
  rightArrow: rightArrow,
  ThreeMobi: ThreeMobi,
  // why do we need digidoc
  whyDoWeNeedDigidoc1: whyDoWeNeedDigidoc1,
  whyDoWeNeedDigidoc2: whyDoWeNeedDigidoc2,
  forDoctors1: forDoctors1,
  forDoctors2: forDoctors2,
  forDoctors3: forDoctors3,
  forDoctors4: forDoctors4,
  forDoctors5: forDoctors5,
  forPatients1: forPatients1,
  forPatients2: forPatients2,
  forPatients3: forPatients3,
  forPatients4: forPatients4,
  forPatients5: forPatients5,
  // digidoc unique
  digidocUnique1: digidocUnique1,
  digidocUnique2: digidocUnique2,
  digidocUnique3: digidocUnique3,
  digidocUnique4: digidocUnique4,
  digidocUnique5: digidocUnique5,
  digidocUnique6: digidocUnique6,
  // register now section
  registerNowImg: registerNowImg,
  // contact us form modal
  contactUsFormImg: contactUsFormImg,
  successImg: successImg,
  closeBtn: closeBtn,
  // book a demo modal
  demoModalImg: demoModalImg,
};

export default Images;

import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Images from "../../commonComponents/Images/Images"
import intlTelInput from 'intl-tel-input'
import { Link, redirect } from "react-router-dom"
import BookADemoModal from "./BookADemoModal"
import axios from "axios"

const ContactForm = ({ onHide, show }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    // const [valid, setValid] = useState(false);
    // const [countryData, setCountryData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [demoModalShow, setDemoModalShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile_no: '',
        tomail: 'info@digidoc.co.in',
        form_type: 'contact',
    });

    // const handlePhoneChange = (isValid, value, countryData, number, id) => {
    //     setPhoneNumber(value);
    //     setValid(isValid);
    //     setCountryData(countryData);
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'mobile_no') {
            if (/^\d{0,10}$/.test(value)) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }

        clearError(name);
    };

    const clearError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check name is not empty
        if (!formData.name) {
            newErrors.name = 'Name is required.';
            formIsValid = false;
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    // api integration of contact form
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const contactFormEndpoint = '/contact';
    const contactFormUrl = `${apiUrl}${contactFormEndpoint}`;

    const submitContactForm = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setSubmitted(true);
            const requestData = {
                name: formData.name,
                email: formData.email,
                mobile_no: formData.mobile_no,
                tomail: formData.tomail,
                form_type: formData.form_type,
            }

            axios.post(contactFormUrl, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        setFormData({
                            name: '',
                            email: '',
                            mobile_no: '',
                            tomail: '',
                            form_type: '',
                        })
                        setSubmitted(true);
                    } else {
                        console.error("Error:", res.data.message);
                        setSubmitted(false);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setSubmitted(false);
                })
        }
    }

    const handleOpenDemoModal = () => {
        handleClose();
        setDemoModalShow(true);
    }

    const handleClose = () => {
        setSubmitted(false);
        onHide();
    };

    return (
        <>
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {submitted &&
                    <Modal.Header>
                        <img src={Images.closeBtn} alt="close-btn" className="img-fluid" onClick={handleClose} />
                    </Modal.Header>
                }
                <Modal.Body>
                    <div className="row mxy-20 align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="img-div">
                                <img src={Images.contactUsFormImg} alt="Contact Us" className="img-fluid" />
                            </div>
                        </div>
                        {!submitted ? (
                            <>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <form className="contactUs-form" onSubmit={submitContactForm}>
                                        <h4 className="text-left mb-2">Get in Touch</h4>
                                        <h5 className="text-left">Contact Form</h5>
                                        <div className="mb-2">
                                            <label for="name"
                                                className="form-label">Name<span>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Enter Full Name here"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="error-message">{errors.name}</p>}
                                        </div>
                                        <div className="mb-2">
                                            <label for="email" className="form-label">Email<span>*</span></label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter email address here"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email && <p className="error-message">{errors.email}</p>}
                                        </div>
                                        <div className="mb-2">
                                            <label for="phone" className="form-label">Phone Number</label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                name="mobile_no"
                                                value={formData.mobile_no}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="btn-div mt-3">
                                            <button className="btn btn-sm btn-yellow" type="submit" disabled={submitted}>{submitted ? "Sending..." : "Submit"}</button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-lg-6 col-md-12 col-sm-12 thankYou-div">
                                    <div className="img-div my-20">
                                        <img src={Images.successImg} alt="Contact Us" width={'120px'} />
                                    </div>
                                    <h5>Thank You!</h5>
                                    <p className="text-center">Our DigiDoc Team will reach out to you to help set up a date and time for you to see DigiDoc demo. </p>
                                    <p className="text-center">
                                        <b>
                                            Would you prefer to set date and time for the demo yourself?
                                        </b>
                                    </p>
                                    <div className="text-center">
                                        <Link onClick={handleOpenDemoModal}>Click here</Link>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal >
            <BookADemoModal
                show={demoModalShow}
                onHide={() => setDemoModalShow(false)} />
        </>
    )
}
export default ContactForm
import React from "react"
import Images from "../../commonComponents/Images/Images"

const WhyNeedDigidoc = () => {

    const whyNeedDigidoc = [
        {
            img: Images.whyDoWeNeedDigidoc1,
            subheading: 'For Doctors',
            listedPoints: [
                { img: Images.forDoctors1, text: 'ABDM Compliant' },
                { img: Images.forDoctors2, text: 'Ensure Patient Adherence' },
                { img: Images.forDoctors3, text: 'Time-Saving Prescription Access and Review' },
                { img: Images.forDoctors4, text: 'Consistent & Continuous Chronic Care' },
                { img: Images.forDoctors5, text: 'Caters To All, Regardless of Tech Comfort' }
            ]
        },
        {
            img: Images.whyDoWeNeedDigidoc2,
            subheading: 'For Patients',
            listedPoints: [
                { img: Images.forPatients1, text: 'Stay on Track With Your Healthcare Regimen' },
                { img: Images.forPatients2, text: 'Easy Doctor Access' },
                { img: Images.forPatients3, text: 'Organized Prescriptions & Medical Info In One Place' },
                { img: Images.forPatients4, text: 'Continuous Chronic Care Support' },
                { img: Images.forPatients5, text: 'Your Data is Safe & Secure' }
            ]
        }
    ]


    return (
        // why do we need digidoc section
        <>
            <section>
                <div className="mxy-40 whyDoWe-need-digidoc-section">
                    <h4>Why do you need DigiDoc?</h4>
                    <div className="row row-25 py-20">
                        {whyNeedDigidoc.map((obj) => (
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="img-div my-20">
                                            <img src={obj.img} alt={`why do we need Digidoc - ${obj.subheading}`} className="img-fluid" />
                                        </div>
                                        <h5>{obj.subheading}</h5>
                                        <div className="listed-points-div">
                                            {obj.listedPoints.map((point, i) => (
                                                <li key={i} className="listed-point d-flex gap-5px">
                                                    <div>
                                                        <img src={point.img} alt={point.text} className="icon-img img-fluid" />
                                                    </div>
                                                    {point.text}
                                                </li>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}
export default WhyNeedDigidoc
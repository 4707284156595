import React, { useState, useEffect } from "react";
import Images from "../Images/Images";
import BookADemoModal from "../../components/HomePageComponents/BookADemoModal";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("#home");
  const [demoModalShow, setDemoModalShow] = useState(false);

  useEffect(() => {
    setActiveLink("#home");
    const handleLoad = () => {
      const sections = document.querySelectorAll("section");

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // setActiveLink(`#${entry.target.id}`);
              const newActiveLink = `#${entry.target.id}`;
              setActiveLink(newActiveLink);

              // Update URL hash without refreshing the page
              if (window.location.hash !== newActiveLink) {
                window.history.pushState(null, null, newActiveLink);
              }
            }
          });
        },
        {
          threshold: 0.5,
        }
      );

      sections.forEach((section) => {
        observer.observe(section);
      });

      return () => {
        sections.forEach((section) => {
          observer.unobserve(section);
        });
      };
    };
    // Run observer setup after the page has fully loaded
    window.addEventListener("load", handleLoad);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  const handleClickActive = (link) => {
    setActiveLink(link);

    // Update URL hash manually
    window.history.pushState(null, null, link);
  };

  return (
    <>
      {/* navbar section */}
      <nav className="navbar fixed-top navbar-expand-lg navbar-light navbar-section align-items-center">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <!-- Image and text --> */}
        <a className="navbar-brand" href="#home">
          <div className="img-div">
            <img
              src={Images.digidoc_logo}
              className="d-inline-block align-top img-fluid"
              alt="Digidoc Logo"
            />
          </div>
        </a>
        <div className="d-flex gap-20 align-items-center d-lg-none">
          <a
            href="https://doctorweb.digidoc.co.in/"
            className="navbar-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            Doctor Login
          </a>
          <div className="btn-div">
            <button
              className="btn btn-sm btn-yellow"
              type="button"
              onClick={() => setDemoModalShow(true)}
            >
              Book a Demo
            </button>
          </div>
        </div>

        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav w-100 justify-content-center gap-20">
            <a
              className={`nav-item nav-link ${
                activeLink === "#home" ? "active" : ""
              }`}
              href="#home"
              onClick={() => handleClickActive("#home")}
            >
              Home
            </a>
            <a
              className={`nav-item nav-link ${
                activeLink === "#aboutUs" ? "active" : ""
              }`}
              href="#aboutUs"
              onClick={() => handleClickActive("#aboutUs")}
            >
              About Us
            </a>
            <a
              className={`nav-item nav-link ${
                activeLink === "#digidocFeatures" ? "active" : ""
              }`}
              href="#digidocFeatures"
              onClick={() => handleClickActive("#digidocFeatures")}
            >
              Features
            </a>
            <a
              className={`nav-item nav-link ${
                activeLink === "#contactUs" ? "active" : ""
              }`}
              href="#contactUs"
              onClick={() => handleClickActive("#contactUs")}
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="d-flex gap-20 align-items-center d-none d-lg-flex">
          <span class="navbar-text"><a
            href="https://doctorweb.digidoc.co.in/"
            className="navbar-text"
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer", textDecoration: "none", color: "inherit" }}
          >
            Doctor Login
          </a></span>
          <div className="btn-div">
            <button
              class="btn btn-sm btn-yellow"
              type="button"
              onClick={() => setDemoModalShow(true)}
            >
              Book a Demo
            </button>
          </div>
        </div>
      </nav>
      <BookADemoModal
        show={demoModalShow}
        onHide={() => setDemoModalShow(false)}
      />
    </>
  );
};
export default NavBar;

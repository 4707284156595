import React, { useEffect, useState } from "react"
import Images from "../../commonComponents/Images/Images"
import BookADemoModal from "./BookADemoModal";

const HomeBanner = () => {

    const [moveIn, setMoveIn] = useState(false);
    const [animate, setAnimate] = useState(true);
    const [demoModalShow, setDemoModalShow] = useState(false);

    const bannerImages = {
        img1: Images.ABDM,
        img2: Images.AWS,
        img3: Images.leftImg,
        img4: Images.centerImg,
        img5: Images.rightImg,
    }

    useEffect(() => {
        setMoveIn(true);

        const timer = setTimeout(() => {
            setAnimate(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [])

    return (
        <>
            <section id="home">
                <div className="homeBanner-section mxy-40 pxy-80 mt-0">
                    <div className="container">
                        <div className="row row-25 align-items-center banner-content">
                            <div className="col-lg-6 col-md-12 col-sm-12 banner-content">
                                <h3 className="text-left">Health Management made Simple</h3>
                                <p>Your patient's Medical Regimen Manager helping manage prescriptions, setting reminders for medications</p>
                                <div className="img-div">
                                    <div className="logo-div d-flex gap-20">
                                        <img src={bannerImages.img1} alt="ABDM logo" className="img-fluid" />
                                        <img src={bannerImages.img2} alt="AWS logo" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="btn-div mt-3">
                                    <button class="btn btn-sm btn-yellow" type="button" onClick={() => setDemoModalShow(true)}>Book a Demo</button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 row gap-20">
                                <div className="img-div">
                                    <img src={Images.digidoc_logo} className="img-fluid" alt="Digidoc Logo" />
                                </div>
                                <div className={`${moveIn ? 'animation-main-div' : ''} ${!animate ? 'pause-animation' : ''}`}>
                                    <div class="circle"></div>
                                    <div class="circle2"></div>
                                    <div class="circle3"></div>
                                </div>
                                <div className={`img-div d-flex align-items-end justify-content-center image-container ${moveIn ? 'move-in' : ''}`}>
                                    <div id="image-left">
                                        <img src={bannerImages.img3} alt="Left Image" className="img-fluid" />
                                    </div>
                                    <div id="image-center">
                                        <img src={bannerImages.img4} alt="Center Image" className="img-fluid" />
                                    </div>
                                    <div id="image-right">
                                        <img src={bannerImages.img5} alt="Right Image" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BookADemoModal
                show={demoModalShow}
                onHide={() => setDemoModalShow(false)} />
        </>
    )
}
export default HomeBanner
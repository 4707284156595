import React from "react"
import Images from "../../commonComponents/Images/Images"

const CriticalFor = () => {

    const thisIsCriticalFor = [
        {
            img: Images.thisIsCriticalFor1,
            gif: Images.thisIsCriticalFor1Gif,
            content: 'Patients with chronic conditions to adhere to prescribed treatment.'
        },
        {
            img: Images.thisIsCriticalFor2,
            gif: Images.thisIsCriticalFor2Gif,
            content: 'Viewing progression of treatment simultaneously.'
        },
        {
            img: Images.thisIsCriticalFor3,
            gif: Images.thisIsCriticalFor3Gif,
            content: 'For those dealing with the hassle of complex systems.'
        },
        {
            img: Images.thisIsCriticalFor4,
            gif: Images.thisIsCriticalFor4Gif,
            content: 'Two way communication between healthcare providers and their patients.'
        },
        {
            img: Images.thisIsCriticalFor5,
            gif: Images.thisIsCriticalFor5Gif,
            content: 'Patients looking for easy-to-use platform available in multiple languages.'
        }
    ]

    return (
        // this is critical for section
        <>
            <section>
                <div className="mxy-40 critical-for-section bg-white">
                    <div className="container">
                        <h4>This is Critical for</h4>
                        <div className="align-items-baseline justify-items-center justify-content-between row py-20">
                            {thisIsCriticalFor.map((obj, index) => (
                                <div className="col-lg-2 col-md-6 col-sm-12 animate__animated animate__fadeInDown mbl-view-critical-for" key={index} style={{ animationDelay: `${index * 0.3}s` }}>
                                    <div className="img-div my-20">
                                        <img src={obj.img} alt={`this critical for ${index + 1}`} className="static img-fluid" />
                                        <img src={obj.gif} alt={`this critical for ${index + 1}`} className="active img-fluid" />
                                    </div>
                                    <p className="text-center">{obj.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CriticalFor
import React, { useState, useEffect } from "react";
import Images from "../../commonComponents/Images/Images";
import DigidocUnique from "./DigidocUnique";
import WhyNeedDigidoc from "./WhyNeedDigidoc";
import CriticalFor from "./CriticalFor";
import Slider from "react-slick";
import HomeBanner from "./HomeBanner";
import DigidocFeatures from "./DigidocFeatures";
import ContactForm from "./ContactForm";
import BookADemoModal from "./BookADemoModal";
import { fa } from "intl-tel-input/i18n";

const HomePageComponents = () => {
  const [modalShow, setModalShow] = useState(false);
  const [demoModalShow, setDemoModalShow] = useState(false);
  const currentYear = new Date().getFullYear();
  // console.log("year", currentYear)

  const [activeSliderImages, setActiveSliderImages] = useState([]);

  const SliderImages = [
    {
      title: "Health Management made Simple",
      content:
        "Your patient's Medical Regimen Manager helping manage prescriptions, setting reminders for medications and home tests.",
      img: Images.slider1,
    },
    {
      title: "Trusted and ABDM Compliant",
      content:
        "DigiDoc integrates with national health records, making us reliable and ensuring security.",
      img: Images.slider2,
    },
    {
      title: "We Speak Your Language",
      content: "Access DigiDoc in your patient’s preferred language.",
      img: Images.slider3,
    },
    {
      title: "Stay Organized, Stay Healthy",
      content:
        "DigiDoc ensures your patients stay on track with their health, with minimal tech skills",
      img: Images.slider4,
    },
    {
      title: "See DigiDoc in Action",
      content:
        "Book a Demo with us today and see how it can benefit you and your practice.",
      img: Images.slider5,
    },
  ];

  const smallSliderImages = [
    {
      img: Images.smallSlider1,
    },
    {
      img: Images.smallSlider2,
    },
    {
      img: Images.smallSlider3,
    },
    {
      img: Images.smallSlider4,
    },
    {
      img: Images.smallSlider5,
    },
  ];

  useEffect(() => {
    const updateSliderImages = () => {
      if (window.innerWidth < 768) {
        setActiveSliderImages(smallSliderImages);
      } else {
        setActiveSliderImages(SliderImages);
      }
    };
    updateSliderImages();

    window.addEventListener("resize", updateSliderImages);

    return () => window.removeEventListener("resize", updateSliderImages);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
  };

  return (
    <>
      <div className="background-clr pb-0">
        {/* home banner section starts */}
        <HomeBanner />
        {/* home banner section ends */}

        <div className="background-Img">
          <div className="container">
            {/* slider starts */}
            <Slider {...settings}>
              {activeSliderImages.map((Images, index) => (
                <div key={index} className="slider-section">
                  <div className="card">
                    <div className="img-div">
                      <img
                        src={Images.img}
                        alt={`slider ${index + 1}`}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            {/* slider ends */}

            {/* why digidoc section */}
            <section id="aboutUs">
              <div>
                <div className="pxy-80 why-digidoc-section">
                  <div className="row align-items-center py-20">
                    <h4 className="text-center">Why Digidoc?</h4>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <div className="img-div my-20">
                        <img
                          src={Images.aboutUsImg}
                          alt="Digidoc"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <p>
                        An ABDM compliant, easy-to-use platform designed to
                        support your patients, particularly those less
                        tech-savvy and dealing with long-standing health
                        concerns.{" "}
                      </p>
                      <p>
                        DigiDoc makes it easier to manage their medical
                        regimens. The app allows healthcare providers like you
                        to stay connected with your patients, ensuring that the
                        prescriptions are uploaded, reminders are set, and
                        medication intake is tracked.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* why digidoc section ends*/}
          </div>
        </div>
      </div>
      {/* this is critical for section starts */}

      <CriticalFor />
      {/* this is critical for section ends */}

      <div className="background-clr">
        <div className="container">
          {/* digidoc features section starts */}

          <DigidocFeatures />

          {/* digidoc features section ends */}

          {/* need digidoc section starts */}

          <WhyNeedDigidoc />

          {/* need digidoc section ends */}

          {/* digidoc unique section starts */}

          <DigidocUnique />

          {/* digidoc unique section ends */}

          {/* register now section */}
          <div className="card registerNow-section mxy-40">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="img-div">
                    <img
                      src={Images.registerNowImg}
                      alt="Register Now"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                  <h4 className="text-left">Register Now </h4>
                  <p className="text-left">
                    Be amongst the first to join the Digidoc community today!
                    Because... no cost for doctors, hospitals, or patients!
                  </p>
                  <div className="btn-div">
                    <button
                      class="btn btn-sm btn-yellow"
                      type="button"
                      onClick={() => setModalShow(true)}
                    >
                      Get in Touch
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* register now section ends*/}

          {/* footer section */}
          <section id="contactUs">
            <div className="footer-section">
              <h4>Contact Us</h4>
              {/* Navigation Section */}
              <div className="footer-navigation">
                {/* Links Section */}
                <div className="links">
                  <a href="#home" className="footer-link">
                    Home
                  </a>
                  <a href="#aboutUs" className="footer-link">
                    About Us
                  </a>
                  <a href="#digidocFeatures" className="footer-link">
                    Features
                  </a>
                  <a href="#contactUs" className="footer-link">
                    Contact Us
                  </a>
                </div>
                {/* Social Media Icons */}
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/Digidochealth"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/digidochealth/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/digitivity-solutions/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@DigiDoc.health"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="YouTube"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
              <hr />
              {/* Enquire Section */}
              <div className="enquire-section">
                <p>
                  Have questions or need assistance with how DigiDoc works? Or
                  anything else you think we can help you with?
                </p>
                <p>
                  Reach out to us at &nbsp; 

                  <span
                    onClick={() => setDemoModalShow(true)}
                    style={{
                      color: "#9d702b",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >  
                       [Book a Demo]
                  </span>
                  .
                </p>
              </div>
            </div>
          </section>
        </div>
        <hr />
        <div className="text-center">
          <span className="copyright-text">
            © Copyrights, <span>{currentYear}</span> Digidoc
          </span>
        </div>
        {/* footer section ends*/}
      </div>
      <ContactForm show={modalShow} onHide={() => setModalShow(false)} />
      <BookADemoModal
        show={demoModalShow}
        onHide={() => setDemoModalShow(false)}
      />
    </>
  );
};
export default HomePageComponents;

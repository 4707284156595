import './App.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import routes from './routes/routes'
//bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css'
//bootstrap js
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// animate css
import 'animate.css/animate.min.css'
// slick css
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// intl-tel-input css
import 'intl-tel-input/build/css/intlTelInput.css'
// media css
import './assets/media/media.css'

import { Helmet } from 'react-helmet'

const live = "" //live
const staging = "/digidocV2"

const App = () => {

  return (
    <div className="App">
      <Helmet>
        <title>Smart & Secure Healthcare Regimen Management</title>
        <meta name="description" content="Discover on-demand doctor apps with advanced regiment tracking. Ideal for patients, doctors, and hospitals looking for efficient medical tools" />
        <link rel="icon" href="https://digidoc.co.in/Digidoc_favicon.ico" type="image/x-icon" />
      </Helmet>
      <Router
        basename={live}
      >
        <Routes>
          {routes.map((route) => {
            return (
              <Route key={route.name} path={route.path} exact={route.exact} name={route.name} element={<route.element />}
              />
            );
          })}
        </Routes>
      </Router>
    </div>
  );
}

export default App

import React from "react"
import Images from "../../commonComponents/Images/Images"

const DigidocUnique = () => {

    const whyUnique = [
        {
            img: Images.digidocUnique1,
            content: 'Fully compliant with ABDM, ensuring easy integration with the national health ecosystem.',
        },
        {
            img: Images.digidocUnique2,
            content: 'Assists patients with in-person prescription uploads, then transitions digitally.',
        },
        {
            img: Images.digidocUnique3,
            content: 'Acts as a reliable partner for effective chronic care management.',
        },
        {
            img: Images.digidocUnique4,
            content: 'Emphasizes continuous connections between patients and their preferred doctors.',
        },
        {
            img: Images.digidocUnique5,
            content: 'Offers local language support to make healthcare management more accessible.',
        },
        {
            img: Images.digidocUnique6,
            content: 'Prescription uploads, medication tracking, and adherence monitoring all into one solution.',
        }
    ]


    return (
        // what makes digidoc unique section
        <>
            <section>
                <div className="mxy-40 digidoc-unique-section">
                    <h4>What makes DigiDoc Unique?</h4>
                    <div className="row row-25 py-20">
                        {whyUnique.map((obj, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="img-div my-20">
                                            <img src={obj.img} alt={`Unique feature ${index + 1}`} className="img-fluid" />
                                        </div>
                                        <p className="text-center">{obj.content}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}
export default DigidocUnique
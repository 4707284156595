import React from "react"
import NavBar from "../../commonComponents/NavBar/NavBar"
import HomePageComponents from "../../components/HomePageComponents/HomePageComponents"

const HomePage = () => {

    return (
        <>
            <NavBar />
            <HomePageComponents />
        </>
    )
}
export default HomePage
import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import Images from "../../commonComponents/Images/Images";

const DigidocFeatures = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const bannerSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesToShow, setImagesToShow] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 900);

  const digidocFeatureImages = [
    {
      images: [Images.prescriptionManagement1, Images.prescriptionManagement2],
      content:
        "Easy upload and storage of prescriptions for instant access anytime.",
    },
    {
      images: [Images.medicationReminders1, Images.medicationReminders2],
      content:
        "Automated alerts to ensure patients with chronic conditions don't miss a dose.",
    },

    {
      images: [Images.doctorConnectivity],
      content:
        "Continuous patient support between visits through smooth communication.",
    },
    {
      // images: [Images.UI1, Images.UI2, Images.UI3],
      images: [Images.ThreeMobi],
      content:
        "Designed for simplicity, making it accessible even to the least tech-savvy users.",
    },
    {
      images: [Images.localLanguageSupport],
      content:
        "Patients can comfortably and practically navigate the app in their preferred language.",
    },
    {
      images: [Images.chornicCare],
      content:
        "Tailored for those managing long-term health concerns with reliable treatment oversight.",
    },
    {
      images: [Images.dataHandling],
      content:
        "Robust protection of patient health data, ensuring complete peace of mind. ",
    },
    {
      images: [Images.adherenceTracking],
      content:
        "Monitor patient medication intake, reinforcing consistency in their treatment.",
    },
  ];

  const digidocFeatureThumbnail = [
    {
      heading: "Prescription Management",
    },
    {
      heading: "Medication Reminders",
    },

    {
      heading: "Doctor Connectivity",
    },
    {
      heading: "User-Friendly Interface",
    },
    {
      heading: "Local Language Support",
    },
    {
      heading: "Focus on Chronic Care",
    },
    {
      heading: "Secure Data Handling",
    },
    {
      heading: "Adherence Tracking",
    },
  ];

  const bannerSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    asNavFor: thumbnailSliderRef.current || null,
    afterChange: (current) => setActiveSlide(current),
  };

  const thumbnailSettings = {
    slidesToShow: 8,
    vertical: false,
    asNavFor: bannerSliderRef.current || null,
    dots: false,
    verticalSwiping: false,
    arrows: true,
    prevArrow: (
      <img
        src={Images.leftArrow}
        alt="left arrow"
        className="img-fluid left-arrow slick-prev slick-arrow"
      />
    ),
    nextArrow: (
      <img
        src={Images.rightArrow}
        alt="right arrow"
        className="img-fluid right-arrow slick-next slick-arrow"
      />
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          vertical: false,
          fade: true,
          slidesToShow: 1,
          focusOnSelect: false,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => setActiveSlide(newIndex),
  };

  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth <= 900;
      setIsMobileView(mobileView);

      if (mobileView && thumbnailSliderRef.current) {
        thumbnailSliderRef.current.slickGoTo(activeSlide);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [activeSlide]);

  useEffect(() => {
    const currentFeature = digidocFeatureImages[activeSlide];

    if (currentFeature && currentFeature.images) {
      setImagesToShow(currentFeature.images);
      setCurrentImageIndex(0);

      const imageInterval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % currentFeature.images.length
        );
      }, 2000);

      return () => clearInterval(imageInterval);
    } else {
      setImagesToShow([]);
    }
  }, [activeSlide]);

  useEffect(() => {
    const autoScrollInterval = setInterval(() => {
      setActiveSlide((prevSlide) => {
        const nextSlide = (prevSlide + 1) % digidocFeatureThumbnail.length;

        if (bannerSliderRef.current) {
          bannerSliderRef.current.slickGoTo(nextSlide);
        }

        if (thumbnailSliderRef.current) {
          thumbnailSliderRef.current.slickGoTo(nextSlide);
        }

        return nextSlide;
      });
    }, 5000);

    return () => clearInterval(autoScrollInterval);
  }, []);

  const handleThumbnailClick = (index) => {
    setActiveSlide(index);
    if (bannerSliderRef.current) {
      bannerSliderRef.current.slickGoTo(index);
    }
  };

  return (
    <>
      <section id="digidocFeatures">
        <div className="mxy-40 digidoc-features-section pxy-80 mt-0">
          <h4 className="text-center">DigiDoc Features</h4>
          <div className="py-20">
            <div className="card">
              <div className="card-body">
                <div className="row row-25 banner-content">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div class="slider slider-for">
                      <div class="slider-banner-image">
                        <Slider
                          {...bannerSettings}
                          ref={bannerSliderRef}
                          className="custom-fade-slider"
                        >
                          {digidocFeatureImages.map((features, index) => (
                            <>
                              <div
                                key={index}
                                className="row align-items-center"
                              >
                                <div className="col-lg-12 col-md-6 col-sm-6">
                                  <div className="img-div my-20">
                                    {imagesToShow.length > 0 && (
                                      <img
                                        src={imagesToShow[currentImageIndex]}
                                        alt={`feature ${index + 1}`}
                                        className="img-fluid"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-sm-6">
                                  <div>
                                    <p>{features.content}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-12 col-sm-12"></div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {isMobileView && (
                      <div class="slider slider-nav">
                        <div class="thumbnail">
                          <div className="cardd">
                            <div className="card-bodyy">
                              <Slider
                                {...thumbnailSettings}
                                ref={thumbnailSliderRef}
                              >
                                {digidocFeatureThumbnail.map(
                                  (features, index) => (
                                    <div
                                      key={index}
                                      className="thumbnail-heading"
                                    >
                                      <p>{features.heading}</p>
                                    </div>
                                  )
                                )}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isMobileView && (
                      <div class="slider slider-nav">
                        <div class="thumbnail">
                          <div className="card">
                            <div className="card-body">
                              {digidocFeatureThumbnail.map(
                                (features, index) => (
                                  <div
                                    key={index}
                                    onClick={() => handleThumbnailClick(index)}
                                    className={
                                      activeSlide === index
                                        ? "thumbnail-heading"
                                        : ""
                                    }
                                  >
                                    <p>{features.heading}</p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DigidocFeatures;
